import React from "react"
import { Button, Tooltip } from "@material-ui/core"
import InputTextCustom from "../../common/input-text-custom"

const ActivationEmailModalChildren = ({
  setModalOpen,
  sendActivationEmail,
  uuid,
  deviceName,
  technicalContactEmail,
  activationEmailInput,
  activationEmailInputTextHandler,
}) => {
  // Return JSX
  return (
    <div className="ags-record-modal-children">
      <p style={{ fontSize: "16px", marginBottom: "10px" }}>
        Would you like to resend the VeloCloud activation email?
      </p>
      <p style={{ fontSize: "16px", fontWeight: "bold", marginBottom: "10px" }}>
        {deviceName}
      </p>
      <Tooltip
        title="Enter multiple emails by separating with a semi-colon (;)"
        placement="top"
        arrow
      >
        <div>
          <InputTextCustom
            name="inputEmail"
            defaultValue={technicalContactEmail}
            inputTextHandler={(evt) => activationEmailInputTextHandler(evt)}
          ></InputTextCustom>
        </div>
      </Tooltip>
      <Button
        style={{ marginRight: "10px" }}
        variant="contained"
        color="secondary"
        onClick={() => sendActivationEmail(uuid, activationEmailInput)}
      >
        Send
      </Button>

      <Button variant="contained" onClick={() => setModalOpen(false)}>
        Cancel
      </Button>
    </div>
  )
}

export default ActivationEmailModalChildren
