import React, { useState } from "react"
import { Tooltip } from "@material-ui/core"
import InputTextCustom from "../../../common/input-text-custom"
import InputSelectCustom from "../../../common/input-select-custom"
import makeInputSelected from "../../../../utilities/make-input-selected"
import extractArrayItemByKey from "../../../../utilities/extract-array-item-by-key"
import "./style.scss"

// Extract tenancy profile type
const extractTenancyProfileType = ({ tenancyUuid, managedTenancies }) => {
  if (!tenancyUuid) return null

  // Extract managed tenancy
  const managedTenancy = extractArrayItemByKey({
    array: managedTenancies,
    keyName: "uuid",
    keyValue: tenancyUuid,
  })

  // Return the tenancy profile type
  return managedTenancy.tenancyProfileType
}

const ManagedDeviceForm = ({
  deviceIndex,
  mode,
  createManagedDeviceModalInputHandler,
  inputOptionsBusinessName,
  managedTenancies,
  deviceName,
  serialNumber,
  sku,
  licenseSku,
  uuid,
  vendorId,
  tenancyUuid,
  customerSiteName,
  customInfo,
  technicalContactName,
  technicalContactEmail,
  configurationId,
  addressId,
  streetAddress,
  locality,
  geographicState,
  postcode,
  serviceState,
  serviceOrderState,
  isEnrichmentRequired,
}) => {
  // Create state for tenancy profile type
  const [tenancyProfileType, setTenancyProfileType] = useState("")

  // Make business name input selected
  const inputSelectedBusinessName = tenancyUuid
    ? makeInputSelected({
        inputOptions: inputOptionsBusinessName,
        inputValue: tenancyUuid,
      })
    : null

  // Update tenancy profile type if tenancyUuid and managedTenancies exists
  if (tenancyUuid && managedTenancies.length > 0) {
    const tenancyProfileUpdated = extractTenancyProfileType({
      tenancyUuid,
      managedTenancies,
    })

    if (tenancyProfileUpdated !== tenancyProfileType)
      setTenancyProfileType(tenancyProfileUpdated)
  }

  // Define geographic state options
  const inputOptionsGeographicState = [
    {
      value: "VIC",
      label: "VIC",
    },
    {
      value: "NSW",
      label: "NSW",
    },
    {
      value: "QLD",
      label: "QLD",
    },
    {
      value: "ACT",
      label: "ACT",
    },
    {
      value: "NT",
      label: "NT",
    },
    {
      value: "WA",
      label: "WA",
    },
    {
      value: "SA",
      label: "SA",
    },
    {
      value: "TAS",
      label: "TAS",
    },
  ]

  // Make Device SKU input selected
  const inputSelectedGeographicState = geographicState
    ? makeInputSelected({
        inputOptions: inputOptionsGeographicState,
        inputValue: geographicState,
      })
    : null

  // Declare the Device SKU options
  let inputOptionsSku = []

  // Update the Device SKU list based on the tenancy profile type
  if (tenancyProfileType === "velocloud") {
    inputOptionsSku = [
      {
        value: "VC-510LTE-AP-HO-12-P",
        label: "VC-510LTE-AP-HO-12-P",
      },
      {
        value: "VC-620-HO-12-P",
        label: "VC-620-HO-12-P",
      },
      {
        value: "VC-680-HO-12-P",
        label: "VC-680-HO-12-P",
      },
      {
        value: "VC-VirtualEdge",
        label: "VC-VirtualEdge",
      },
      {
        value: "-",
        label: "-",
      },
    ]
  }

  // Declare the License SKU options
  let inputOptionsLicenseSku = []

  // Update the License SKU list based on the tenancy profile type
  if (tenancyProfileType === "velocloud") {
    inputOptionsLicenseSku = [
      {
        value: "VC100M-ENT-HO-SG-L34S1-12P",
        label: "VC100M-ENT-HO-SG-L34S1-12P",
      },
      {
        value: "VC200M-ENT-HO-SG-L34S1-12P",
        label: "VC200M-ENT-HO-SG-L34S1-12P",
      },
      {
        value: "VC500M-ENT-HO-SG-L34S1-12P",
        label: "VC500M-ENT-HO-SG-L34S1-12P",
      },
      {
        value: "VC002G-ENT-HO-SG-L34S1-12P",
        label: "VC002G-ENT-HO-SG-L34S1-12P",
      },
      {
        value: "VC050M-ENT-HO-SG-L34S1-12P",
        label: "VC050M-ENT-HO-SG-L34S1-12P",
      },
      {
        value: "-",
        label: "-",
      },
    ]
  }

  // Make Device SKU input selected
  const inputSelectedSku =
    tenancyProfileType && sku
      ? makeInputSelected({
          inputOptions: inputOptionsSku,
          inputValue: sku,
        })
      : null

  // Make Device SKU input selected
  const inputSelectedLicenseSku =
    tenancyProfileType && licenseSku
      ? makeInputSelected({
          inputOptions: inputOptionsLicenseSku,
          inputValue: licenseSku,
        })
      : null

  // Return JSX
  return (
    <div className="managed-device-form" key={deviceIndex}>
      <Tooltip
        title="The device name will be automatically generated upon activation"
        placement="top-start"
        arrow
      >
        <div>
          <InputTextCustom
            name="deviceName"
            value={deviceName}
            placeholder="Device Name"
            inputTextHandler={(evt) =>
              createManagedDeviceModalInputHandler(evt, deviceIndex)
            }
            disabled={true}
          />
        </div>
      </Tooltip>

      {serialNumber ? (
        <InputTextCustom
          name="serialNumber"
          value={serialNumber}
          placeholder="Serial Number"
          inputTextHandler={(evt) =>
            createManagedDeviceModalInputHandler(evt, deviceIndex)
          }
          disabled={true}
        />
      ) : null}
      <InputTextCustom
        name="businessName"
        value={inputSelectedBusinessName ? inputSelectedBusinessName.label : ""}
        placeholder="Business Name"
        inputTextHandler={(evt) =>
          createManagedDeviceModalInputHandler(evt, deviceIndex)
        }
        disabled={true}
      />
      <InputTextCustom
        name="sku"
        value={inputSelectedSku ? inputSelectedSku.label : ""}
        placeholder="Device SKU"
        inputTextHandler={(evt) =>
          createManagedDeviceModalInputHandler(evt, deviceIndex)
        }
        disabled={true}
      />
      <InputTextCustom
        name="licenseSku"
        value={inputSelectedLicenseSku ? inputSelectedLicenseSku.label : ""}
        placeholder="License SKU"
        inputTextHandler={(evt) =>
          createManagedDeviceModalInputHandler(evt, deviceIndex)
        }
        disabled={true}
      />
      {mode === "VIEW" || mode === "DELETE" ? (
        <InputTextCustom
          name="uuid"
          value={uuid}
          placeholder="Device UUID"
          inputTextHandler={(evt) =>
            createManagedDeviceModalInputHandler(evt, deviceIndex)
          }
          disabled={true}
        />
      ) : null}

      {tenancyProfileType === "velocloud" ? (
        <>
          {serviceState === "active" &&
          (mode === "VIEW" || mode === "DELETE") ? (
            <>
              <InputTextCustom
                name="vendorId"
                value={vendorId}
                placeholder="Device Vendor ID"
                inputTextHandler={(evt) =>
                  createManagedDeviceModalInputHandler(evt, deviceIndex)
                }
                disabled={true}
              />
              <InputTextCustom
                name="configurationId"
                value={configurationId}
                placeholder="Configuration ID"
                inputTextHandler={(evt) =>
                  createManagedDeviceModalInputHandler(evt, deviceIndex)
                }
                disabled={true}
              />
            </>
          ) : null}
          <Tooltip
            title="A custom name for the device that may be set to any value"
            placement="top-start"
            arrow
          >
            <div>
              <InputTextCustom
                name="customerSiteName"
                value={customerSiteName}
                placeholder={
                  isEnrichmentRequired
                    ? "Custom Device Name*"
                    : "Custom Device Name"
                }
                inputTextHandler={(evt) =>
                  createManagedDeviceModalInputHandler(evt, deviceIndex)
                }
                disabled={!isEnrichmentRequired}
              />{" "}
            </div>
          </Tooltip>
          <Tooltip
            title="An optional free text field. The value provided will be added as metadata on the VeloCloud Orchestrator"
            placement="top-start"
            arrow
          >
            <div>
              <InputTextCustom
                name="customInfo"
                value={customInfo}
                placeholder="Custom Data"
                inputTextHandler={(evt) =>
                  createManagedDeviceModalInputHandler(evt, deviceIndex)
                }
                disabled={!isEnrichmentRequired}
              />
            </div>
          </Tooltip>
          <Tooltip
            title="The contact name and email are used for the VeloCloud activation email"
            placement="top-start"
            arrow
          >
            <div>
              <InputTextCustom
                name="technicalContactName"
                value={technicalContactName}
                placeholder="Technical Contact Name"
                inputTextHandler={(evt) =>
                  createManagedDeviceModalInputHandler(evt, deviceIndex)
                }
                disabled={true}
              />
            </div>
          </Tooltip>
          <Tooltip
            title="The contact name and email are used for the VeloCloud activation email"
            placement="top-start"
            arrow
          >
            <div>
              <InputTextCustom
                name="technicalContactEmail"
                value={technicalContactEmail}
                placeholder="Technical Contact Email"
                inputTextHandler={(evt) =>
                  createManagedDeviceModalInputHandler(evt, deviceIndex)
                }
                disabled={true}
              />
            </div>
          </Tooltip>
        </>
      ) : null}
      <InputTextCustom
        name="addressId"
        value={addressId}
        placeholder="Address ID"
        inputTextHandler={(evt) =>
          createManagedDeviceModalInputHandler(evt, deviceIndex)
        }
        disabled={true}
      />
      <InputTextCustom
        name="streetAddress"
        value={streetAddress}
        placeholder="Street Address"
        inputTextHandler={(evt) =>
          createManagedDeviceModalInputHandler(evt, deviceIndex)
        }
        disabled={true}
      />
      <InputTextCustom
        name="locality"
        value={locality}
        placeholder="City/Suburb"
        inputTextHandler={(evt) =>
          createManagedDeviceModalInputHandler(evt, deviceIndex)
        }
        disabled={true}
      />
      {isEnrichmentRequired ? (
        <InputSelectCustom
          inputName="geographicState"
          inputSelected={inputSelectedGeographicState}
          inputOptions={inputOptionsGeographicState}
          inputReadOnly={false}
          inputSelectHandler={(evt) =>
            createManagedDeviceModalInputHandler(evt, deviceIndex)
          }
          isSearchable={true}
          placeholder="State/Territory"
          isClearable={false}
          disabled={true}
        />
      ) : (
        <InputTextCustom
          name="geographicState"
          value={geographicState}
          placeholder="State/Territory"
          inputTextHandler={null}
          disabled={true}
        />
      )}

      <InputTextCustom
        name="postcode"
        value={postcode}
        placeholder="Postcode"
        inputTextHandler={(evt) =>
          createManagedDeviceModalInputHandler(evt, deviceIndex)
        }
        disabled={true}
      />
    </div>
  )
}

export default ManagedDeviceForm
