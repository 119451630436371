import axios from "axios"
import { v4 as uuidv4 } from "uuid"
import envUtil from "../../utilities/env-util"

// Execute enrichment workflow
const httpReqEnrichDevice = ({ serviceOrderUuid, reqBodyEnrichDevice }) =>
  new Promise((resolve, reject) => {
    // Execute the Camunda process
    axios({
      method: "PATCH",
      baseURL: envUtil.getBaseURL(envUtil.tenants.activationsCamunda),
      url: `/mnaas/technicalEnrichment/v1/serviceOrder/managedDevice/${serviceOrderUuid}`,
      headers: getHeaders(),
      data: reqBodyEnrichDevice,
      withCredentials: envUtil.getPlatform() === "openshift",
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const getHeaders = () => {
  const headersForCmi = {
    "Authentication-Data": "Bearer " + localStorage.getItem("cmiPortalToken"),
    "X-Correlation-ID": uuidv4(),
    "X-Group-ID": "MNaaS",
  }
  const headersForPcf = {
    Authorization: "Bearer " + localStorage.getItem("cmiPortalToken"),
    "X-Correlation-ID": uuidv4(),
    "X-Group-ID": "B2BSmallandMediumBusiness",
  }
  const headers =
    envUtil.getPlatform() === "openshift" ? headersForCmi : headersForPcf
  return headers
}

export default httpReqEnrichDevice
