const makeIsEnrichmentRequired = (devices) => {
  for (const device of devices) {
    if (
      device.serviceOrderState !== "inProgress" &&
      device.serviceOrderState !== "pending"
    )
      return false
  }
  return true
}

export default makeIsEnrichmentRequired
