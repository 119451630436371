// Extract related tenancy UUID from device
const extractRelatedTenancyUuidFromDevice = (serviceRelationship) => {
  for (const serviceRelationshipItem of serviceRelationship) {
    if (serviceRelationshipItem.relationshipType === "dependencyOn")
      return serviceRelationshipItem.service.id
  }
  return "..."
}

export default extractRelatedTenancyUuidFromDevice
