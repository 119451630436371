import axios from "axios"
import { v4 as uuidv4 } from "uuid"
import envUtil from "../../utilities/env-util"

// Send device activation email by UUID
const httpReqSendEmail = (uuid, activationEmailInput) => {
  new Promise((resolve, reject) => {
    axios({
      method: "POST",
      baseURL: envUtil.getBaseURL(envUtil.tenants.activationsCamunda),
      url: `/activationAndConfiguration/v4/service/managedDevice/${uuid}/activationEmail`,
      headers: getHeaders(),
      withCredentials: envUtil.getPlatform() === "openshift",
      data: {
        activationEmailRecipients: activationEmailInput,
      },
    })
      .then((response) => {
        return resolve(response)
      })
      .catch((error) => {
        return reject(error)
      })
  })
}
const getHeaders = () => {
  const headersForCmi = {
    "Authentication-Data": "Bearer " + localStorage.getItem("cmiPortalToken"),
    "X-Correlation-ID": uuidv4(),
    "X-Group-ID": "MNaaS",
  }
  const headersForPcf = {
    Authorization: "Bearer " + localStorage.getItem("cmiPortalToken"),
    "X-Correlation-ID": uuidv4(),
    "X-Group-ID": "B2BSmallandMediumBusiness",
  }
  const headers =
    envUtil.getPlatform() === "openshift" ? headersForCmi : headersForPcf
  return headers
}

export default httpReqSendEmail
