import axios from "axios"
import envUtil from "../../utilities/env-util"
import httpReqCreateTokenTso from "./http-req-create-token-tso"

// Delete a managed device by UUID
const httpReqDeleteDevice = async (reqBodyDeleteDevice) => {
  // Create the TSO token
  const token = await httpReqCreateTokenTso()

  // Execute the TSO process
  axios({
    method: "POST",
    baseURL: envUtil.getBaseURL(envUtil.tenants.activationsTso),
    url: "/tsoproxy/baocdp/rest/process/%3ATelstra_MNaaS_B2B%3APrimary_Processes%3ADelete-Device/execute?mode=sync",
    headers: {
      "Authentication-Token": token,
      "Content-Type": "application/json",
    },
    withCredentials: true,
    data: reqBodyDeleteDevice,
  })
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}

export default httpReqDeleteDevice
