import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"
import ReactTooltip from "react-tooltip"
import { isEmpty } from "lodash"
import { Typography } from "@material-ui/core"
import { ThemeProvider } from "@material-ui/core/styles"
import TelstraTheme from "../../gatsby-theme-material-ui-top-layout/theme.js"
import Layout from "../../components/layout/layout.js"
import TableCustom from "../../components/common/table-custom/index.js"
import ToastContent from "../../components/common/toast-content.js"
import "react-toastify/dist/ReactToastify.css"
import { toast, ToastContainer } from "react-toastify"
import getManagedDeviceColumns from "../../components/activations/get-managed-device-columns.js"
import ModalCustom from "../../components/common/modal-custom/index.js"
import CreateManagedDeviceModalChildren from "../../components/activations/create-managed-device-modal-children/index.js"
import httpReqCreateDevice from "../../services/activations/http-req-create-device"
import httpReqEnrichDevice from "../../services/activations/http-req-enrich-device"
import httpReqListDevices from "../../services/activations/http-req-list-devices"
import httpReqListTenancies from "../../services/activations/http-req-list-tenancies"
import isUserAnInternalAdmin from "../../services/activations/is-user-an-internal-admin"
import extractArrayItemByKey from "../../utilities/extract-array-item-by-key"
import makeManagedDevicesDbToRuntime from "../../services/activations/make-managed-devices-db-to-runtime.js"
import makeMenuOptions from "../../services/activations/make-menu-options.js"
import httpReqDeleteDevice from "../../services/activations/http-req-delete-device.js"
import httpReqGetDeviceTMF640 from "../../services/activations/http-req-get-device-tmf-640.js"
import httpReqGetDeviceTMF641 from "../../services/activations/http-req-get-device-tmf-641.js"
import makeDevicesNumberUpdated from "../../services/activations/make-devices-number-updated"
import isStringAValidInteger from "../../utilities/is-string-a-valid-integer"
import httpReqCreateTokenTso from "../../services/activations/http-req-create-token-tso.js"
import extractServiceCharacteristicByKey from "../../services/activations/extract-service-characteristic-by-key.js"
import extractAddressAttributeByKey from "../../services/activations/extract-address-attribute-by-key.js"
import extractRelatedTenancyUuidFromDevice from "../../services/activations/extract-related-tenancy-uuid-from-device.js"
import envUtil from "../../utilities/env-util"
import { getUser } from "../../utilities/userManager"
import ActivationEmailModalChildren from "../../components/activations/activation-email-modal-children/index.js"
import httpReqSendEmail from "../../services/activations/http-req-send-email.js"
import { useDispatch } from "react-redux"
import {
  enableLoading,
  disableLoading,
} from "../../components/common/state/loadingSlice.js"

// Define the initial state for the create managed tenancy modal
const makeCreateManagedDeviceModalInitialState = () => ({
  numberOfDevices: 1,
  modalOpen: false,
  mode: "CREATE",
  devices: [
    {
      deviceName: "",
      serialNumber: "",
      uuid: "",
      vendorId: "",
      tenancyUuid: "",
      sku: "",
      licenseSku: "",
      customerSiteName: "",
      customInfo: "",
      technicalContactName: "",
      technicalContactEmail: "",
      configurationId: "",
      addressId: "",
      streetAddress: "",
      locality: "",
      geographicState: "",
      postcode: "",
      serviceState: "",
      serviceOrderState: "",
      serviceOrderUuid: "",
      serviceOrderItemUuid: "",
    },
  ],
})

// Define the initial state for the activation email modal
const makeActivationEmailModalInitialState = () => ({
  modalOpen: false,
  uuid: "",
  deviceName: "",
  email: "",
})

const Devices = () => {
  const dispatch = useDispatch()
  // Use state for createManagedDeviceModal
  const [createManagedDeviceModal, setCreateManagedDeviceModal] = useState(
    makeCreateManagedDeviceModalInitialState()
  )

  // Use state for activationEmailModal
  const [activationEmailModal, setActivationEmailModal] = useState(
    makeActivationEmailModalInitialState()
  )

  const [activationEmailInput, setActivationEmailInput] = useState("")
  // Use state for managedDevicesTableData
  const [managedDevicesTableData, setManagedDevicesTableData] = useState([])

  // Create the state for checkboxesSelected
  const [checkboxesSelected, setCheckboxesSelected] = React.useState([])

  // Create the state for hidden columns
  const [hiddenColumns, setHiddenColumns] = useState(["uuid"])

  // Show/Hide columns click handler
  const showHideColumnsClickHandler = () => {
    isEmpty(hiddenColumns) ? setHiddenColumns(["uuid"]) : setHiddenColumns([])
  }

  // Create managed tenancy modal open handler
  const createManagedDeviceModalOpenHandler = () => {
    setCreateManagedDeviceModal({
      ...makeCreateManagedDeviceModalInitialState(),
      modalOpen: true,
    })
  }

  // Make the license ID from the licenseSKU
  const makeEdgeLicenseIdFromSku = (licenseSku) => {
    switch (licenseSku) {
      case "VC100M-ENT-HO-SG-L34S1-12P":
        return 94
      case "VC200M-ENT-HO-SG-L34S1-12P":
        return 121
      case "VC500M-ENT-HO-SG-L34S1-12P":
        return 148
      case "VC002G-ENT-HO-SG-L34S1-12P":
        return 202
      default:
        return 1
    }
  }

  // Enrich managed device handler
  const enrichManagedDevice = async () => {
    console.log("Enriching Managed Device...")

    try {
      for (const device of createManagedDeviceModal.devices) {
        const reqBodyEnrichDevice = {
          serviceOrderItem: [
            {
              id: device.serviceOrderItemUuid,
              service: {
                serviceCharacteristic: [
                  {
                    name: "managementDomainProfile",
                    value: {
                      customerSiteName: device.customerSiteName,
                      customInfo: device.customInfo,
                    },
                  },
                ],
              },
            },
          ],
        }

        dispatch(enableLoading())
        if (envUtil.getPlatform() === "pcf") getUser()
        // Execute the API request
        await httpReqEnrichDevice({
          reqBodyEnrichDevice,
          serviceOrderUuid: device.serviceOrderUuid,
        })
      }
      // Close the modal
      setCreateManagedDeviceModal({
        ...createManagedDeviceModal,
        modalOpen: false,
      })

      // Display a success message
      toast.info(
        <ToastContent>
          Success - the enrichment workflow is now complete
        </ToastContent>
      )

      // Refresh the device table now that enrichment is complete
      refreshDevicesTableData()

      // Uncheck all boxes now that enrichment is complete
      setCheckboxesSelected([])
    } catch (error) {
      console.log(error)
      // Display an error
      toast.error(<ToastContent>{error.message}</ToastContent>)
    } finally {
      dispatch(disableLoading())
    }
  }

  // Create managed device handler
  const createManagedDevice = async () => {
    try {
      // Create the TSO token
      dispatch(enableLoading())
      const token = await httpReqCreateTokenTso()
      for (const device of createManagedDeviceModal.devices) {
        // Make request body
        const reqBodyCreateDevice = {
          inputParameters: [
            {
              name: "deviceName",
              value: device.deviceName,
            },
            {
              name: "skus",
              value: JSON.stringify([
                { type: "device", sku: device.sku },
                {
                  type: "license",
                  sku: device.licenseSku,
                },
              ]),
            },
            {
              name: "serialNumber",
              value: device.serialNumber,
            },
            {
              name: "tenancyUuid",
              value: device.tenancyUuid,
            },
            {
              name: "edgeLicenseId",
              value: makeEdgeLicenseIdFromSku(device.licenseSku),
            },
            {
              name: "technicalContactName",
              value: device.technicalContactName,
            },
            {
              name: "technicalContactEmail",
              value: device.technicalContactEmail,
            },
            {
              name: "addressId",
              value: device.addressId,
            },
            {
              name: "streetAddress",
              value: device.streetAddress,
            },
            {
              name: "locality",
              value: device.locality,
            },
            {
              name: "geographicState",
              value: device.geographicState,
            },
            {
              name: "postcode",
              value: device.postcode,
            },
          ],
        }

        // Execute the API request
        await httpReqCreateDevice({ reqBodyCreateDevice, token })
      }
      // Close the modal
      setCreateManagedDeviceModal({
        ...createManagedDeviceModal,
        modalOpen: false,
      })

      // Display a success message
      toast.info(
        <ToastContent>
          Success - the enrichment workflow is now complete
        </ToastContent>
      )

      // Refresh the device table now that enrichment is complete
      refreshDevicesTableData()

      // Uncheck all boxes now that enrichment is complete
      setCheckboxesSelected([])
    } catch (error) {
      console.log(error)

      // Display an error
      toast.error(
        <ToastContent>
          Error - the device workflow failed to commence
        </ToastContent>
      )
    } finally {
      dispatch(disableLoading())
    }
  }

  // Set modal open - create managed tenancy modal
  const setModalOpenCreateManagedTenancy = (modalOpen) => {
    setCreateManagedDeviceModal({
      ...createManagedDeviceModal,
      modalOpen,
    })
  }

  // Set modal open - activation email modal
  const setModalOpenActivationEmail = (modalOpen) => {
    setActivationEmailModal({
      ...activationEmailModal,
      modalOpen,
    })
  }

  // Set number of devices modal
  const setNumberOfDevicesModal = (numberOfDevicesUpdated) => {
    // Return early if the value isn't a valid integer
    if (
      !isStringAValidInteger(numberOfDevicesUpdated) ||
      Number.parseInt(numberOfDevicesUpdated) < 1
    )
      return

    // Update the number of device forms
    setCreateManagedDeviceModal({
      ...createManagedDeviceModal,
      numberOfDevices: numberOfDevicesUpdated,
      devices: makeDevicesNumberUpdated({
        devices: createManagedDeviceModal.devices,
        numberOfDevices: numberOfDevicesUpdated,
      }),
    })
  }

  // Input handler
  const createManagedDeviceModalInputHandler = (evt, index) => {
    // Clone the current devices array
    const devicesUpdated = [...createManagedDeviceModal.devices]

    // Update the devices array
    devicesUpdated[Number.parseInt(index)][evt.target.name] = evt.target.value

    // Update the devices state
    setCreateManagedDeviceModal({
      ...createManagedDeviceModal,
      devices: devicesUpdated,
    })
  }

  // Update managed devices table
  const refreshDevicesTableData = async () => {
    try {
      // List all managed devices
      dispatch(enableLoading())
      if (envUtil.getPlatform() === "pcf") getUser()

      const httpResBodyListDevices = await httpReqListDevices()
      // List all managed tenancies
      const httpResBodyListTenancies = await httpReqListTenancies()

      // Update the table
      setManagedDevicesTableData(
        makeManagedDevicesDbToRuntime({
          httpResBodyListDevices,
          httpResBodyListTenancies,
        })
      )
    } catch (error) {
      console.log(error)
      toast.error(
        <ToastContent>Error - failed to retrieve managed devices</ToastContent>
      )
    } finally {
      dispatch(disableLoading())
    }
  }

  // Update device modal from CMDB
  const refreshDeviceModalData = async ({ uuid, mode }) => {
    try {
      dispatch(enableLoading())
      if (envUtil.getPlatform() === "pcf") getUser()
      // Get device by UUID TMF640
      const httpResBodyGetDeviceTMF640 = await httpReqGetDeviceTMF640(uuid)
      // Extract managementDomainProfile
      const managementDomainProfile = extractArrayItemByKey({
        array: httpResBodyGetDeviceTMF640.serviceCharacteristic,
        keyName: "name",
        keyValue: "managementDomainProfile",
      })

      // Check if enrichment is required
      const isEnrichmentRequired =
        httpResBodyGetDeviceTMF640.state.toLowerCase() === "initial"

      // Make customerSiteName
      const customerSiteName =
        managementDomainProfile &&
        managementDomainProfile.value &&
        managementDomainProfile.value.customerSiteName
          ? managementDomainProfile.value.customerSiteName
          : ""

      // Make deviceName
      const deviceName =
        managementDomainProfile &&
        managementDomainProfile.value &&
        managementDomainProfile.value.deviceName
          ? managementDomainProfile.value.deviceName
          : managementDomainProfile &&
            managementDomainProfile.value &&
            managementDomainProfile.value.siteName
          ? managementDomainProfile.value.siteName
          : ""

      // Make customInfo
      const customInfo =
        managementDomainProfile &&
        managementDomainProfile.value &&
        managementDomainProfile.value.customInfo
          ? managementDomainProfile.value.customInfo
          : ""

      // Make technicalContactName
      const technicalContactName =
        managementDomainProfile &&
        managementDomainProfile.value &&
        managementDomainProfile.value.technicalContactName
          ? managementDomainProfile.value.technicalContactName
          : managementDomainProfile &&
            managementDomainProfile.value &&
            managementDomainProfile.value.contactName
          ? managementDomainProfile.value.contactName
          : ""

      // Make technicalContactEmail
      const technicalContactEmail =
        managementDomainProfile &&
        managementDomainProfile.value &&
        managementDomainProfile.value.technicalContactEmail
          ? managementDomainProfile.value.technicalContactEmail
          : managementDomainProfile &&
            managementDomainProfile.value &&
            managementDomainProfile.value.contactEmail
          ? managementDomainProfile.value.contactEmail
          : ""

      // Make configurationId
      const configurationId =
        managementDomainProfile &&
        managementDomainProfile.value &&
        managementDomainProfile.value.configurationId
          ? managementDomainProfile.value.configurationId
          : ""

      // Make vendorId
      const vendorId =
        managementDomainProfile &&
        managementDomainProfile.value &&
        managementDomainProfile.value.vendorDeviceId
          ? managementDomainProfile.value.vendorDeviceId
          : ""

      // Get device by UUID TMF641
      const httpResBodyGetDeviceTMF641 = await httpReqGetDeviceTMF641(
        extractArrayItemByKey({
          array: managedDevicesTableData,
          keyName: "uuid",
          keyValue: uuid,
        }).serviceOrderUuid
      )

      // Extract related tenancy UUID
      const tenancyUuid = extractRelatedTenancyUuidFromDevice(
        httpResBodyGetDeviceTMF640.serviceRelationship
      )

      // Extract skus from the response
      const skus = extractServiceCharacteristicByKey({
        serviceCharacteristics:
          httpResBodyGetDeviceTMF640.serviceCharacteristic,
        key: "sku",
      })

      const skuPart = extractArrayItemByKey({
        array: skus,
        keyName: "value",
        keyValue: "Part",
      })

      const licenseSkuObject = extractArrayItemByKey({
        array: skus,
        keyName: "value",
        keyValue: "License",
      })

      // Update the modal
      setCreateManagedDeviceModal({
        mode,
        modalOpen: true,
        devices: [
          {
            uuid: uuid,
            vendorId,
            deviceName: !isEnrichmentRequired
              ? deviceName
              : deviceName === ""
              ? "Awaiting Activation"
              : deviceName,
            serialNumber: extractServiceCharacteristicByKey({
              serviceCharacteristics:
                httpResBodyGetDeviceTMF640.serviceCharacteristic,
              key: "serialNumberPrimary",
            }),
            sku: skuPart ? skuPart.name : "-",
            licenseSku: licenseSkuObject ? licenseSkuObject.name : "-",
            tenancyUuid,
            customerSiteName,
            customInfo,
            technicalContactName,
            technicalContactEmail,
            configurationId,
            addressId: extractAddressAttributeByKey({
              place: httpResBodyGetDeviceTMF640.place,
              key: "id",
            }),
            streetAddress: extractAddressAttributeByKey({
              place: httpResBodyGetDeviceTMF640.place,
              key: "streetAddress",
            }),
            locality: extractAddressAttributeByKey({
              place: httpResBodyGetDeviceTMF640.place,
              key: "suburb",
            }),
            geographicState: extractAddressAttributeByKey({
              place: httpResBodyGetDeviceTMF640.place,
              key: "stateTerritory",
            }),
            postcode: extractAddressAttributeByKey({
              place: httpResBodyGetDeviceTMF640.place,
              key: "postcode",
            }),
            serviceState: httpResBodyGetDeviceTMF640.state,
            serviceOrderState: httpResBodyGetDeviceTMF641.state,
            serviceOrderUuid: httpResBodyGetDeviceTMF641.id,
            serviceOrderItemUuid:
              httpResBodyGetDeviceTMF641.serviceOrderItem[0].id,
          },
        ],
      })
    } catch (error) {
      console.log(error)
      toast.error(<ToastContent>{error.message}</ToastContent>)
    } finally {
      dispatch(disableLoading())
    }
  } // Update device modal from CMDB

  const refreshDeviceModalDataMultiple = async (activationList, mode) => {
    console.log("Fetching Device Details...")

    try {
      dispatch(enableLoading())
      if (envUtil.getPlatform() === "pcf") getUser()
      // Get device by UUID TMF640
      const promises = activationList.uuid.map(async (uuid) => {
        const httpResBodyGetDeviceTMF640 = await httpReqGetDeviceTMF640(uuid)
        return httpResBodyGetDeviceTMF640
      })

      const httpResBodyGetDeviceTMF640Multiple = await Promise.all(promises)
      var customerSiteName = []
      var customInfo = []
      var technicalContactName = []
      var technicalContactEmail = []
      var configurationId = []
      var vendorId = []
      var deviceName = []

      for (let index = 0; index < activationList.uuid.length; index++) {
        // Extract managementDomainProfile
        const managementDomainProfile = extractArrayItemByKey({
          array:
            httpResBodyGetDeviceTMF640Multiple[index].serviceCharacteristic,
          keyName: "name",
          keyValue: "managementDomainProfile",
        })

        // Make customerSiteName
        const customerSiteNameM =
          managementDomainProfile &&
          managementDomainProfile.value &&
          managementDomainProfile.value.customerSiteName
            ? managementDomainProfile.value.customerSiteName
            : ""

        // Make deviceName
        const deviceNameM =
          managementDomainProfile &&
          managementDomainProfile.value &&
          managementDomainProfile.value.deviceName
            ? managementDomainProfile.value.deviceName
            : managementDomainProfile &&
              managementDomainProfile.value &&
              managementDomainProfile.value.siteName
            ? managementDomainProfile.value.siteName
            : ""

        // Make customInfo
        const customInfoM =
          managementDomainProfile &&
          managementDomainProfile.value &&
          managementDomainProfile.value.customInfo
            ? managementDomainProfile.value.customInfo
            : ""

        // Make technicalContactName
        const technicalContactNameM =
          managementDomainProfile &&
          managementDomainProfile.value &&
          managementDomainProfile.value.technicalContactName
            ? managementDomainProfile.value.technicalContactName
            : managementDomainProfile &&
              managementDomainProfile.value &&
              managementDomainProfile.value.contactName
            ? managementDomainProfile.value.contactName
            : ""

        // Make technicalContactEmail
        const technicalContactEmailM =
          managementDomainProfile &&
          managementDomainProfile.value &&
          managementDomainProfile.value.technicalContactEmail
            ? managementDomainProfile.value.technicalContactEmail
            : managementDomainProfile &&
              managementDomainProfile.value &&
              managementDomainProfile.value.contactEmail
            ? managementDomainProfile.value.contactEmail
            : ""

        // Make configurationId
        const configurationIdM =
          managementDomainProfile &&
          managementDomainProfile.value &&
          managementDomainProfile.value.configurationId
            ? managementDomainProfile.value.configurationId
            : ""

        // Make vendorId
        const vendorIdM =
          managementDomainProfile &&
          managementDomainProfile.value &&
          managementDomainProfile.value.vendorDeviceId
            ? managementDomainProfile.value.vendorDeviceId
            : ""

        customerSiteName.push(customerSiteNameM)
        customInfo.push(customInfoM)
        technicalContactName.push(technicalContactNameM)
        technicalContactEmail.push(technicalContactEmailM)
        configurationId.push(configurationIdM)
        vendorId.push(vendorIdM)
        deviceName.push(deviceNameM)
      }
      // Get device by UUID TMF641
      const promise = activationList.uuid.map(async (uuid) => {
        const httpResBodyGetDeviceTMF641 = await httpReqGetDeviceTMF641(
          extractArrayItemByKey({
            array: managedDevicesTableData,
            keyName: "uuid",
            keyValue: uuid,
          }).serviceOrderUuid
        )
        return httpResBodyGetDeviceTMF641
      })

      const httpResBodyGetDeviceTMF641Multiple = await Promise.all(promise)
      // Extract skus from the response
      var skus = []
      var isEnrichmentRequired = []
      var tenancyUuid = []
      for (let index = 0; index < activationList.uuid.length; index++) {
        const skumultiple = extractServiceCharacteristicByKey({
          serviceCharacteristics:
            httpResBodyGetDeviceTMF640Multiple[index].serviceCharacteristic,
          key: "sku",
        })
        // Check if enrichment is required
        const isEnrichmentRequiredM =
          httpResBodyGetDeviceTMF640Multiple[index].state.toLowerCase() ===
          "initial"
        isEnrichmentRequired.push(isEnrichmentRequiredM)
        skus.push(skumultiple)

        // Extract related tenancy UUID
        const tenancyUuidM = extractRelatedTenancyUuidFromDevice(
          httpResBodyGetDeviceTMF640Multiple[index].serviceRelationship
        )
        tenancyUuid.push(tenancyUuidM)
      }
      var arrayOfDevices = []

      for (let index = 0; index < activationList.uuid.length; index++) {
        const skuPart = extractArrayItemByKey({
          array: skus[index],
          keyName: "value",
          keyValue: "Part",
        })

        const licenseSkuObject = extractArrayItemByKey({
          array: skus,
          keyName: "value",
          keyValue: "License",
        })

        arrayOfDevices.push({
          uuid: activationList.uuid[index],
          vendorId: vendorId[index],
          deviceName: !isEnrichmentRequired[index]
            ? deviceName[index]
            : deviceName === ""
            ? "Awaiting Activation"
            : deviceName[index],
          serialNumber: extractServiceCharacteristicByKey({
            serviceCharacteristics:
              httpResBodyGetDeviceTMF640Multiple[index].serviceCharacteristic,
            key: "serialNumberPrimary",
          }),
          sku: skuPart ? skuPart.name : "-",
          licenseSku: licenseSkuObject ? licenseSkuObject.name : "-",
          tenancyUuid: tenancyUuid[index],
          customerSiteName: customerSiteName[index],
          customInfo: customInfo[index],
          technicalContactName: technicalContactName[index],
          technicalContactEmail: technicalContactEmail[index],
          configurationId: configurationId[index],
          addressId: extractAddressAttributeByKey({
            place: httpResBodyGetDeviceTMF640Multiple[index].place,
            key: "id",
          }),
          streetAddress: extractAddressAttributeByKey({
            place: httpResBodyGetDeviceTMF640Multiple[index].place,
            key: "streetAddress",
          }),
          locality: extractAddressAttributeByKey({
            place: httpResBodyGetDeviceTMF640Multiple[index].place,
            key: "suburb",
          }),
          geographicState: extractAddressAttributeByKey({
            place: httpResBodyGetDeviceTMF640Multiple[index].place,
            key: "stateTerritory",
          }),
          postcode: extractAddressAttributeByKey({
            place: httpResBodyGetDeviceTMF640Multiple[index].place,
            key: "postcode",
          }),
          serviceState: httpResBodyGetDeviceTMF640Multiple[index].state,
          serviceOrderState: httpResBodyGetDeviceTMF641Multiple[index].state,
          serviceOrderUuid: httpResBodyGetDeviceTMF641Multiple[index].id,
          serviceOrderItemUuid:
            httpResBodyGetDeviceTMF641Multiple[index].serviceOrderItem[0].id,
        })
      }

      // Update the modal
      setCreateManagedDeviceModal({
        numberOfDevices: activationList.uuid.length,
        mode: activationList.mode,
        modalOpen: true,
        devices: arrayOfDevices,
      })
    } catch (error) {
      console.log(error)
      toast.error(<ToastContent>{error.message}</ToastContent>)
    } finally {
      dispatch(disableLoading())
    }
  }

  // Table row click handler
  const tableRowClickHandler = ({ row }) => {
    refreshDeviceModalData({ uuid: row.original.uuid, mode: "VIEW" })
  }

  const activateDeviceClickHandler = (activationList) => {
    refreshDeviceModalDataMultiple({ uuid: activationList, mode: "VIEW" })
  }

  // Delete tenancy confirmed handler
  const deleteTenancyConfirmedHandler = async (uuid) => {
    try {
      // Close the modal
      setCreateManagedDeviceModal({
        ...createManagedDeviceModal,
        modalOpen: false,
      })

      // Define the request body
      const reqBodyDeleteDevice = {
        inputParameters: [
          {
            name: "uuid",
            value: uuid,
          },
        ],
      }

      // Execute the workflow to delete the device
      dispatch(enableLoading())
      await httpReqDeleteDevice(reqBodyDeleteDevice)
      // Display a success message
      toast.info(<ToastContent>Success - the device was deleted</ToastContent>)

      // Reload the table data after 1 second
      setTimeout(() => {
        refreshDevicesTableData()
      }, 1000)
    } catch (error) {
      // Display an error message
      toast.error(<ToastContent>{error.message}</ToastContent>)
    } finally {
      dispatch(disableLoading())
    }
  }

  // Open the activation email modal
  const openActivationEmailModal = async ({ evt, uuid }) => {
    // Stop the device modal from opening
    evt.stopPropagation()
    dispatch(enableLoading())
    const httpResBodyGetDeviceTMF641 = await httpReqGetDeviceTMF641(
      extractArrayItemByKey({
        array: managedDevicesTableData,
        keyName: "uuid",
        keyValue: uuid,
      }).serviceOrderUuid
    )
    dispatch(disableLoading())
    // Get technical contact email
    const technicalContactEmail = extractArrayItemByKey({
      array:
        httpResBodyGetDeviceTMF641.serviceOrderItem[0].service
          .serviceCharacteristic,
      keyName: "name",
      keyValue: "managementDomainProfile",
    }).value.technicalContactEmail
    setActivationEmailInput(technicalContactEmail)

    // Open the Activation Email modal
    setActivationEmailModal({
      ...activationEmailModal,
      modalOpen: true,
      uuid,
      deviceName: extractArrayItemByKey({
        array: managedDevicesTableData,
        keyName: "uuid",
        keyValue: uuid,
      }).deviceName,
      email: technicalContactEmail,
    })
  }

  const activationEmailInputTextHandler = (evt) => {
    setActivationEmailInput(evt.target.value)
  }

  // Send the activation email
  const sendActivationEmail = async (uuid, activationEmailInput) => {
    try {
      setActivationEmailModal({ ...activationEmailModal, modalOpen: false })
      dispatch(enableLoading())
      await httpReqSendEmail(uuid, activationEmailInput)
      toast.info(
        <ToastContent>
          Success - the activation email has been sent
        </ToastContent>
      )
    } catch (error) {
      toast.info(<ToastContent>{error.message}</ToastContent>)
    } finally {
      dispatch(disableLoading())
    }
  }

  // Component did mount
  useEffect(() => {
    refreshDevicesTableData()
  }, [])

  // Rebuild the tooltip when the state is updated
  useEffect(() => {
    ReactTooltip.rebuild()
  })

  // Hide the tooltip when the create device modal is opened
  useEffect(() => {
    if (createManagedDeviceModal.modalOpen) ReactTooltip.hide()
  }, [createManagedDeviceModal])

  return (
    <Layout menuOptions={makeMenuOptions()}>
      <ThemeProvider theme={TelstraTheme}>
        <Helmet title="MNaaS Activations - Devices" />
        <ToastContainer
          position="top-center"
          autoClose={3000}
          pauseOnHover={false}
          pauseOnFocusLoss={false}
          className="toast-container"
          toastClassName="toast-info"
          bodyClassName="toast-body"
        />
        <ReactTooltip className="tooltip-custom" multiline={true} />
        <div className="app-wrapper">
          <Typography variant="h2" gutterBottom={true}>
            MNaaS Activations
          </Typography>
          <TableCustom
            title="Managed Devices"
            columns={getManagedDeviceColumns({
              openActivationEmailModal,
            })}
            data={managedDevicesTableData}
            tableRowClickHandler={tableRowClickHandler}
            activateDeviceClickHandler={activateDeviceClickHandler}
            addButtonVisible={false}
            addButtonClickHandler={createManagedDeviceModalOpenHandler}
            addButtonData={null}
            checkboxesSelected={checkboxesSelected}
            setCheckboxesSelected={setCheckboxesSelected}
            refreshButtonVisible={true}
            refreshButtonClickHandler={refreshDevicesTableData}
            checkboxesEnabled={true}
            hiddenColumns={hiddenColumns}
            showHideColumnsClickHandler={showHideColumnsClickHandler}
            initialState={{
              pageIndex: 0,
              sortBy: [
                {
                  id: "createdAt",
                  desc: true,
                },
              ],
            }}
          />
          <ModalCustom
            modalOpen={createManagedDeviceModal.modalOpen}
            setModalOpen={setModalOpenCreateManagedTenancy}
            modalTitle="Managed Device"
          >
            <CreateManagedDeviceModalChildren
              numberOfDevices={createManagedDeviceModal.numberOfDevices}
              setNumberOfDevicesModal={setNumberOfDevicesModal}
              setModalOpen={setModalOpenCreateManagedTenancy}
              createManagedDeviceModalInputHandler={
                createManagedDeviceModalInputHandler
              }
              deleteTenancyConfirmedHandler={deleteTenancyConfirmedHandler}
              createManagedDevice={createManagedDevice}
              enrichManagedDevice={enrichManagedDevice}
              mode={createManagedDeviceModal.mode}
              devices={createManagedDeviceModal.devices}
            />
          </ModalCustom>
          <ModalCustom
            modalOpen={activationEmailModal.modalOpen}
            setModalOpen={setModalOpenActivationEmail}
            modalTitle="Activation Email"
          >
            <ActivationEmailModalChildren
              setModalOpen={setModalOpenActivationEmail}
              sendActivationEmail={sendActivationEmail}
              uuid={activationEmailModal.uuid}
              deviceName={activationEmailModal.deviceName}
              technicalContactEmail={activationEmailModal.email}
              activationEmailInput={activationEmailInput}
              activationEmailInputTextHandler={activationEmailInputTextHandler}
            />
          </ModalCustom>
        </div>
      </ThemeProvider>
    </Layout>
  )
}

export default Devices
