import extractArrayItemByKey from "../../utilities/extract-array-item-by-key"
import extractAddressAttributeByKey from "../../services/activations/extract-address-attribute-by-key"
import extractServiceCharacteristicByKey from "../../services/activations/extract-service-characteristic-by-key"

// Convert managed devices from DB to runtime
const makeManagedDevicesDbToRuntime = ({
  httpResBodyListDevices,
  httpResBodyListTenancies,
}) => {
  const managedDevices = []
  for (const managedDeviceDb of httpResBodyListDevices) {
    // Extract the address
    const streetAddress = extractAddressAttributeByKey({
      place: managedDeviceDb.place,
      key: "streetAddress",
    })
    const locality = extractAddressAttributeByKey({
      place: managedDeviceDb.place,
      key: "suburb",
    })
    const geographicState = extractAddressAttributeByKey({
      place: managedDeviceDb.place,
      key: "stateTerritory",
    })
    const postcode = extractAddressAttributeByKey({
      place: managedDeviceDb.place,
      key: "postcode",
    })

    const fullAddress =
      streetAddress +
      "<br />" +
      locality +
      "<br />" +
      geographicState +
      " " +
      postcode

    const serialNumber = extractArrayItemByKey({
      array: managedDeviceDb.serviceCharacteristic,
      keyName: "name",
      keyValue: "serialNumberPrimary",
    }).value
    const managementDomainProfile = extractArrayItemByKey({
      array: managedDeviceDb.serviceCharacteristic,
      keyName: "name",
      keyValue: "managementDomainProfile",
    })
    const sku = extractArrayItemByKey({
      array: managedDeviceDb.serviceCharacteristic,
      keyName: "name",
      keyValue: "sku",
    })
    const skuPart = sku
      ? extractArrayItemByKey({
          array: sku.value,
          keyName: "value",
          keyValue: "Part",
        })
      : null

    managedDevices.push({
      deviceName:
        managementDomainProfile &&
        managementDomainProfile.value &&
        managementDomainProfile.value.deviceName
          ? managementDomainProfile.value.deviceName
          : managementDomainProfile &&
            managementDomainProfile.value &&
            managementDomainProfile.value.siteName
          ? managementDomainProfile.value.siteName
          : managedDeviceDb.state.toLowerCase() === "initial" ||
            managedDeviceDb.state.toLowerCase() === "pending" ||
            managedDeviceDb.state.toLowerCase() === "inProgress"
          ? "Awaiting Activation"
          : "...",
      customerSiteName:
        managementDomainProfile &&
        managementDomainProfile.value &&
        managementDomainProfile.value.customerSiteName
          ? managementDomainProfile.value.customerSiteName
          : "...",
      serialNumber: serialNumber ? serialNumber : "...",
      fullAddress,
      sku: skuPart ? skuPart.name : "-",
      businessName: extractBusinessNameFromTenancy({
        tenancyUuid: managedDeviceDb.serviceRelationship[0].service.id,
        httpResBodyListTenancies,
      }),
      uuid: managedDeviceDb.id,
      state: managedDeviceDb.state,
      createdAt: managedDeviceDb.serviceDate,
      serviceOrderUuid:
        managedDeviceDb.serviceOrderItem && managedDeviceDb.serviceOrderItem[0]
          ? managedDeviceDb.serviceOrderItem[0].serviceOrderId
          : "...",
    })
  }
  return managedDevices
}

// Identify the business name belonging to device
const extractBusinessNameFromTenancy = ({
  tenancyUuid,
  httpResBodyListTenancies,
}) => {
  for (const tenancy of httpResBodyListTenancies) {
    if (tenancy.id === tenancyUuid) {
      return extractServiceCharacteristicByKey({
        serviceCharacteristics: tenancy.serviceCharacteristic,
        key: "businessName",
      })
    }
  }
  return "..."
}

export default makeManagedDevicesDbToRuntime
