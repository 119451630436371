// Make the new devices forms
const makeDevicesNumberUpdated = ({ devices, numberOfDevices }) => {
  const devicesUpdated = []
  for (let i = 0; i < numberOfDevices; i += 1) {
    if (devices.length > i) {
      devicesUpdated.push({ ...devices[i] })
    } else {
      devicesUpdated.push({ ...devices[0] })
    }
  }
  return devicesUpdated
}

export default makeDevicesNumberUpdated
