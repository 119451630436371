import React from "react"
import { FaEnvelope } from "react-icons/fa"

const getManagedDeviceColumns = ({ openActivationEmailModal }) => [
  {
    Header: "Device Name",
    accessor: "deviceName",
    Cell: (props) => (
      <>
        {props.value === "Awaiting Activation" ? (
          <span style={{ fontStyle: "italic" }}>{props.value}</span>
        ) : (
          <span data-tip={props.row.original.fullAddress}>{props.value}</span>
        )}
      </>
    ),
  },
  { Header: "Custom Device Name", accessor: "customerSiteName" },
  { Header: "Business Name", accessor: "businessName" },
  { Header: "Serial Number", accessor: "serialNumber" },
  { Header: "SKU", accessor: "sku" },
  { Header: "UUID", accessor: "uuid", id: "uuid" },
  { Header: "State", accessor: "state" },
  {
    Header: "Created At",
    accessor: "createdAt",
    minWidth: 30,
    width: 50,
    maxWidth: 200,
    filter: "date",
    Cell: (props) => new Date(props.value).toLocaleString(),
  },
  {
    Header: "",
    accessor: "uuid",
    id: "action-icons",
    maxWidth: "100",
    disableFilters: true,
    sortable: false,
    Cell: (props) => (
      <div className="action-icons">
        <div data-tip="This will resend the VeloCloud activation email to a list of nominated users.">
          {props.row.original.state === "active" ? (
            <FaEnvelope
              className="edit-icon"
              onClick={(evt) =>
                openActivationEmailModal({ evt, uuid: props.value })
              }
            />
          ) : null}
        </div>
      </div>
    ),
  },
]

export default getManagedDeviceColumns
