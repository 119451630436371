// Extract a service characteristic
const extractAddressAttributeByKey = ({ place, key }) => {
  for (const placeItem of place) {
    if (placeItem === null) return "..."
    if (
      placeItem.role === "InstallationAddress" &&
      placeItem.name === "adborId"
    )
      return placeItem[key]
  }
  return "..."
}

export default extractAddressAttributeByKey
