// Make the inputSelected object for a drop-down select component
const makeInputSelected = ({ inputOptions, inputValue }) => {
  for (const inputOption of inputOptions) {
    if (inputValue === inputOption.value) {
      return inputOption
    }
  }
  return inputOptions[0]
}

export default makeInputSelected
