import axios from "axios"
import envUtil from "../../utilities/env-util"

// Create a managed device
const httpReqCreateDevice = async ({ reqBodyCreateDevice, token }) => {
  // Execute the TSO process
  axios({
    method: "POST",
    baseURL: envUtil.getBaseURL(envUtil.tenants.activationsTso),
    url: "/tsoproxy/baocdp/rest/process/%3ATelstra_MNaaS_B2B%3APrimary_Processes%3ACreate-Device/execute?mode=async",
    headers: {
      "Authentication-Token": token,
      "Content-Type": "application/json",
    },
    withCredentials: true,
    data: reqBodyCreateDevice,
  })
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}

export default httpReqCreateDevice
