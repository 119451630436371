import React, { useEffect, useState } from "react"
import { Button, Tooltip } from "@material-ui/core"
import InputTextCustom from "../../common/input-text-custom"
import httpReqListTenancies from "../../../services/activations/http-req-list-tenancies"
import extractArrayItemByKey from "../../../utilities/extract-array-item-by-key"
import makeManagedTenanciesDbToRuntime from "../../../services/activations/make-managed-tenancies-db-to-runtime"
import makeIsEnrichmentRequired from "../../../services/activations/make-is-enrichment-required"
import ToastContent from "../../../components/common/toast-content.js"
import { toast } from "react-toastify"
import ManagedDeviceForm from "./managed-device-form"

// Make business name input options
const makeInputOptionsBusinessName = (managedTenancies) => {
  const inputOptionsBusinessName = []
  for (const managedTenancy of managedTenancies) {
    inputOptionsBusinessName.push({
      value: managedTenancy.uuid,
      label: managedTenancy.businessName,
    })
  }
  return inputOptionsBusinessName
}

const CreateManagedDeviceModalChildren = ({
  numberOfDevices,
  setNumberOfDevicesModal,
  mode,
  setModalOpen,
  createManagedDeviceModalInputHandler,
  deleteTenancyConfirmedHandler,
  createManagedDevice,
  enrichManagedDevice,
  devices,
}) => {
  // Create state for managed tenancies
  const [managedTenancies, setManagedTenancies] = useState([])

  // Create state for business name input options
  const [inputOptionsBusinessName, setInputOptionsBusinessName] = useState([])

  // State to disable enrich button during enrichment
  const [enrichButtonDisabled, setEnrichButtonDisabled] = useState(false)

  const enrichDeviceHandler = () => {
    setEnrichButtonDisabled(true)
    enrichManagedDevice()
  }

  // Update tenancies list
  const refreshTenancies = async () => {
    try {
      // List all managed tenancies
      const httpResBodyListTenancies = await httpReqListTenancies()

      // Make managed tenancies
      const managedTenanciesRuntime = makeManagedTenanciesDbToRuntime(
        httpResBodyListTenancies
      )

      // Save managed tenancies
      setManagedTenancies(managedTenanciesRuntime)

      // Make business name input options
      setInputOptionsBusinessName(
        makeInputOptionsBusinessName(managedTenanciesRuntime)
      )
    } catch (error) {
      console.log(error)
      toast.error(
        <ToastContent>
          Error - failed to retrieve managed tenancies
        </ToastContent>
      )
    }
  }

  // Component did mount
  useEffect(() => {
    refreshTenancies()
  }, [])

  // Check if the devices are awaiting enrichment
  const isEnrichmentRequired = makeIsEnrichmentRequired(devices)

  // Return JSX
  return (
    <div className="ags-record-modal-children">
      <form>
        {mode === "CREATE" ? (
          <Tooltip
            title="Create up to 10 devices at a time"
            placement="top-start"
            arrow
          >
            <div>
              <InputTextCustom
                name="numberOfDevices"
                value={numberOfDevices}
                placeholder="Number of Devices*"
                inputTextHandler={(evt) =>
                  setNumberOfDevicesModal(evt.target.value)
                }
                disabled={false}
              />
            </div>
          </Tooltip>
        ) : null}
        {devices.map(
          (
            {
              deviceName,
              serialNumber,
              sku,
              licenseSku,
              uuid,
              vendorId,
              tenancyUuid,
              customerSiteName,
              customInfo,
              technicalContactName,
              technicalContactEmail,
              configurationId,
              addressId,
              streetAddress,
              locality,
              geographicState,
              postcode,
              serviceState,
              serviceOrderState,
            },
            deviceIndex
          ) => (
            <ManagedDeviceForm
              mode={mode}
              key={deviceIndex}
              inputOptionsBusinessName={inputOptionsBusinessName}
              managedTenancies={managedTenancies}
              deviceIndex={deviceIndex}
              createManagedDeviceModalInputHandler={
                createManagedDeviceModalInputHandler
              }
              deviceName={deviceName}
              serialNumber={serialNumber}
              sku={sku}
              licenseSku={licenseSku}
              uuid={uuid}
              vendorId={vendorId}
              tenancyUuid={tenancyUuid}
              customerSiteName={customerSiteName}
              customInfo={customInfo}
              technicalContactName={technicalContactName}
              technicalContactEmail={technicalContactEmail}
              configurationId={configurationId}
              addressId={addressId}
              streetAddress={streetAddress}
              locality={locality}
              geographicState={geographicState}
              postcode={postcode}
              serviceState={serviceState}
              serviceOrderState={serviceOrderState}
              isEnrichmentRequired={isEnrichmentRequired}
            />
          )
        )}
      </form>
      {mode === "CREATE" ? (
        <Button
          style={{ marginRight: "10px" }}
          variant="contained"
          color="secondary"
          onClick={createManagedDevice}
        >
          Create
        </Button>
      ) : null}
      {mode === "VIEW" && isEnrichmentRequired ? (
        <Button
          style={{ marginRight: "10px" }}
          variant="contained"
          color="secondary"
          disabled={enrichButtonDisabled}
          onClick={enrichDeviceHandler}
        >
          {enrichButtonDisabled ? `Enriching` : `Enrich`}
        </Button>
      ) : null}
      {mode === "DELETE" ? (
        <Button
          style={{ marginRight: "10px" }}
          variant="contained"
          color="primary"
          onClick={() => deleteTenancyConfirmedHandler(devices[0].uuid)}
        >
          Delete
        </Button>
      ) : null}
      {mode === "CREATE" || mode === "DELETE" ? (
        <Button variant="contained" onClick={() => setModalOpen(false)}>
          Cancel
        </Button>
      ) : null}
      {mode === "VIEW" ? (
        <Button variant="contained" onClick={() => setModalOpen(false)}>
          Done
        </Button>
      ) : null}
    </div>
  )
}

export default CreateManagedDeviceModalChildren
